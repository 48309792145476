<template>
  <div class="paymentResult text-center flex flex-col scroll-box h-full relative xl:mx-24 xl:pt-10 <xl:mx-[28px] <xl:pt-[28px]">
    <header :class="resultInfo.iconColor">
      <div class="result-box flex flex-row justify-center items-center xl:text-2xl <xl:text-[30px] font-medium">
          <img class="inline-block mr-[4PX] xl:w-12.5 xl:h-12 <xl:w-[50px] <xl:h-[48px]" :src="resultInfo.iconUrl" alt="" />
          <div>{{ resultInfo.iconText }}</div>
      </div>
    </header>
    <div class="main flex-auto xl:mt-8 <xl:mt-[32px]">
      <van-cell-group :border="false">
        <van-cell title-class="cell-title" value-class="cell-value" :border="false" title="Payment Amount" :value="'₹' + (resultInfo.totalAmount || 0)" />
        <van-cell title-class="cell-title" value-class="cell-value" :border="false" title="Payment Method" v-if="resultInfo.paymentMethod" :value="resultInfo.paymentMethod" />
        <van-cell title-class="cell-title" value-class="cell-value" :border="false" title="Payment Time" v-if="resultInfo.txnTime" :value="resultInfo.txnTime" />
        <van-cell title-class="cell-title" value-class="cell-value" :border="false" title="Payment ID" v-if="resultInfo.transactionId" >
          <template v-slot:default>
            <div class="flex justify-end">
              <div class="truncate">{{resultInfo.transactionId}}</div>
              <div class="flex-none">
                | <a href="javascript:;" class="copy underline decoration-current text-$theme-color" @click="copyText(resultInfo.transactionId)">Copy</a>
              </div>
            </div>
          </template>
        </van-cell>
        <van-cell title-class="cell-title" value-class="cell-value" :border="false" title="Merchant Name" v-if="resultInfo.merchantName">
          <template v-slot:default>
            <div class="flex justify-end">
              <div class="truncate">{{resultInfo.merchantName}}</div>
              <div class="flex-none">
                 | <a href="javascript:;" class="copy underline decoration-current text-$theme-color" @click="copyText(resultInfo.merchantName)">Copy</a>
              </div>
            </div>
          </template>
        </van-cell>
        <van-cell title-class="cell-title" value-class="cell-value " :border="false" title="Order ID" v-if="resultInfo.txnRefId">
          <template v-slot:default>
            <div class="flex justify-end">
              <div class="truncate">{{resultInfo.txnRefId}}</div>
              <div class="flex-none">
                | <a href="javascript:;" class="copy underline decoration-current text-$theme-color" @click="copyText(resultInfo.txnRefId)">Copy</a>
              </div>
            </div>
          </template>
        </van-cell>
        <van-cell title-class="cell-title" value-class="cell-value text-ellipsis2" :border="false" title="Product Name" :value="resultInfo.productName" v-if="resultInfo.productName" />
        <van-cell title-class="cell-title" value-class="cell-value text-ellipsis3" :border="false" title="Remark" v-if="resultInfo.remark" :value="resultInfo.remark" />
      </van-cell-group>
    </div>
    <div class="w-full <xl:pb-[1PX] xl:pb-10">
      <button class="rounded-[26PX] bg-$theme-color text-$color-fff w-full xl:h-19 xl:text-3xl <xl:h-[64px] <xl:text-[32px]" @click="close">{{resultInfo.status === 'SUCCESS' ? 'Confirm' : 'Close'}}</button>
      <div class="share flex items-center justify-center xl:mt-8 <xl:mt-[20px]">
        <!-- <div class="border border-$black-color2 rounded-[26PX] xl:w-25 xl:h-10 xl:text-sm <xl:w-[120px] <xl:h-[36px] <xl:text-[14px] flex items-center justify-center mx-1">
          <img src="../assets/icon_share.png" class="xl:w-6 xl:h-5 xl:mr-2 <xl:w-[23px] <xl:h-[23px] <xl:mr-[8px]" alt="">
          <span>Share</span>
        </div> -->
        <div @click="saveMask(true)" class="border border-$black-color2 rounded-[26PX] xl:w-25 xl:h-10 xl:text-sm <xl:w-[120px] <xl:h-[36px] <xl:text-[14px] flex items-center justify-center mx-1">
          <img src="../assets/icon_save.png" class="xl:w-5 xl:h-5 xl:mr-2 <xl:w-[20px] <xl:h-[20px] <xl:mr-[8px]" alt="">
          <span>Save</span>
        </div>
      </div>
    </div>
    <Camera :resultInfo="resultInfo" type="result" @saveMask="saveMask" v-if="showCamera"></Camera>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import clipboard3 from 'vue-clipboard3';
import {getPaymentResult} from "@/api/payment";
import Camera from "@/components/Camera";
import { Toast } from "vant";
import { reactive, ref, onMounted } from "vue";

const iconSuccess = require('../assets/icon_success.png');
const iconFail = require('../assets/icon_fail.png');
const iconPending = require('../assets/icon_pending.png');

const router = useRouter();

let resultInfo = reactive({
  iconUrl: iconFail,
  iconText: 'Payment failed',
  iconColor: 'text-$fail-color',
});

//UPI支付结果
let getResult;
let upiResult = async () => {
    let data = await getPaymentResult({},{noAlert:true});
    const status = data.status || 'FAILURE';
    let obj = {
      "PENDING": {
        iconUrl: iconPending,
        iconText: 'Pending',
        iconColor: 'text-$pending-color'
      },
      "SUCCESS": {
        iconUrl: iconSuccess,
        iconText: 'Payment Successfully',
        iconColor: 'text-$success-color'
      },
      "FAILURE": {
        iconUrl: iconFail,
        iconText: 'Payment Failed',
        iconColor: 'text-$fail-color',
      },
      "CLOSED": {
        iconUrl: iconFail,
        iconText: 'Payment Closed',
        iconColor: 'text-$fail-color',
      }
    };
    if( status === "PENDING"){
      //未支付重试
      clearTimeout(getResult);
      getResult = setTimeout(() => {
        upiResult();
      }, 1000);
    }
    resultInfo = Object.assign(resultInfo, obj[status], data);
}

onMounted(() => {
  upiResult();
});

let copyText = async (text) => {
  const { toClipboard } = clipboard3();
  try {
    await toClipboard(text);
    Toast('Copy successful!')
  } catch (error) {
    Toast("Copy failed!")
  }
}
const showCamera = ref(false);
const saveMask = (type)=>{
  showCamera.value = type;
}

let close = () => {
  let redirectLink = sessionStorage.getItem("redirectLink")
  if(redirectLink){
    // 有重定向地址跳转
    window.location.replace(redirectLink);
  }else{
    // 无重定向返回 通过window.open方式打开关闭窗口
    const isOPen = window.opener && window.opener !== window
    isOPen && window.close();
    router.back()
  }
};
</script>

<style lang="scss" type="text/scss">
.paymentResult {
  .main {
    .van-cell-group{
      .van-cell{
        padding: 0;
        line-height: 16px;
        font-size: 14px;
        margin-bottom: 20px;
        .cell-title{
          text-align: left;
          font-weight: 400;
          color: var(--black-color)
        }
        .cell-value{
          text-align: right;
          flex: 1;
          font-weight: 300;
          color: var(--black-color)
        }
      }
    }
  }
}
</style>
